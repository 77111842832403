.root {
  display: flex;
  position: relative;
  margin-left: -112px;
  width: calc(100% + 112px);
  height: 100%;
}

.onboardingImage {
  position: absolute;
  top: 97px;
  left: 0;
  max-width: unset;
  height: auto;
  overflow-x: hidden;
  user-select: none;
}

.onboardingAIBadge {
  position: relative;
}

.onboardingAIBadge::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(
    to right,
    var(--color-gradient-feature-ai-1),
    var(--color-gradient-feature-ai-2),
    var(--color-gradient-feature-ai-3)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
